@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%; /* Ensure the body and html elements are full height */
  margin: 0; /* Remove default margin */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (max-width: 767px) {
  .tooltip {
    right: 0 !important;
  }
}
.fade {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
  font-weight: revert;
}

#sales_letter h1 {
  margin-bottom: 16px;
  line-height: 38px;
}
#sales_letter h2 {
  margin-bottom: 12px;
  margin-top: 12px;
}
#sales_letter h3 {
  margin-bottom: 8px;
  margin-top: 8px;
}
#sales_letter p {
  margin-bottom: 20px;
}
#sales_letter ul {
  padding: revert;
  margin: revert;
  list-style: revert;
}
@media (max-width: 767px) {
  #sales_letter p {
    margin-bottom: 12px;
  }
}
